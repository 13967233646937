import React from 'react';
import PropTypes from 'prop-types';
import s from './Button.module.scss';

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  level: PropTypes.string,
  theme: PropTypes.string,
  onClick: PropTypes.func,
  center: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
  dataAttribute: PropTypes.string,
};

const setLevelClass = (level) => {
  switch (level) {
    case 'primary':
      return s.btnPrimary;
    case 'primary2':
      return s.btnPrimary2;
    case 'primary3':
      return s.btnPrimary3;
    case 'secondary':
      return s.btnSecondary;
    case 'secondary2':
      return s.btnSecondary2;
    case 'tertiary':
      return s.btnTertiary;
    case 'tertiary2':
      return s.btnTertiary2;
    case 'tertiary3':
      return s.btnTertiary3;
    case 'textCTA':
      return s.btnTextCTA;
    case 'textLink':
      return s.btnTextLink;
    case 'textShowOff':
      return s.btnTextShowOff;
    case 'textShowOn':
      return s.btnTextShowOn;
    case 'infoButton':
      return s.infoButton;
    default:
      return s.btnPrimary;
  }
};

const setThemeClass = (theme) => {
  switch (theme) {
    case 'dark':
      return s.darkTheme;
    case 'grey':
      return s.greyTheme;
    case 'light':
      return s.lightTheme;
    case 'gold':
      return s.goldTheme;
    case 'white':
      return s.whiteTheme;
    case 'transparent':
      return s.transparentTheme;
    case 'purple':
      return s.lightPurpleTheme;
    case 'darkPurple':
      return s.darkPurpleTheme;
    default:
      return s.darkTheme;
  }
};

const Button = ({
  label = '',
  component: Component = 'button',
  level = 'primary',
  theme = 'dark',
  onClick = () => {},
  center = false,
  disabled = false,
  isLoading = false,
  marginTop = 3,
  marginBottom = 0,
  className = '',
  id = 'button',
  width = '',
  title = null,
  dataAttribute = '',
  ...otherProps
}) => {
  const dynamicAttribute = {};
  if (dataAttribute) dynamicAttribute[dataAttribute] = 'true';

  return (
    <Component
      {...otherProps}
      style={{
        margin: center ? '0 auto' : null,
        marginTop,
        marginBottom,
        width,
      }}
      {...dynamicAttribute}
      onClick={onClick}
      id={id}
      title={title || label}
      className={`${setLevelClass(level)} ${setThemeClass(theme)} ${className}`}
      disabled={disabled}
    >
      {!isLoading ? label : <span className={s.spinner} />}
    </Component>
  );
};

Button.propTypes = propTypes;
export default Button;
