import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { trackClickEvent, trackEvent } from '@utils/analytics';
import { useGlobalState as useNavigationGlobalState } from '@/src/globalStates/NavigationState';
import AnchorLink from '@components/AnchorLink';
import SharedLib from 'wizarding-world-web-shared';
import { SIGNUP_NOTIFICATION_SEEN } from '@constants/index';
import { REGISTER } from '../../constants/routes';
import icon1 from './img/icon-house.svg';
import icon2 from './img/icon-patronus.svg';
import icon3 from './img/icon-wand.svg';
import icon4 from './img/icon-portrait.svg';
import s from './SignUpPanel.module.scss';

const propTypes = {
  onComplete: PropTypes.func,
  signupNotificationSeen: PropTypes.bool,
  isSignupBtnHovered: PropTypes.bool,
};

const SignUpPanel = ({
  onComplete = () => {},
  signupNotificationSeen = true,
  isSignupBtnHovered = false,
}) => {
  const { setCookie } = SharedLib.utils.cookie;
  const [isVisible, setVisibility] = useState(true);
  const [shrink, setShrink] = useState(false);
  const [fadeOutLinks, setFadeOutLinks] = useState(false);
  const [navigationHiddenByScroll] = useNavigationGlobalState('navigationHiddenByScroll');
  const [hasWaited, setHasWaited] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [panelHovered, setPanelHovered] = useState(false);
  const [panelBlurred, setPanelBlurred] = useState(false);
  const panelRef = useRef(null);

  useEffect(() => {
    if (isVisible && !signupNotificationSeen && !hasCompleted) {
      panelRef.current.focus();

      trackEvent('Popup Displayed', {
        location: 'Nav Bar',
        duration: 5,
        label: 'Sign Up',
      });
    }
  }, [hasCompleted, isVisible, signupNotificationSeen]);

  useEffect(() => {
    setVisibility(!navigationHiddenByScroll && !hasCompleted);
  }, [hasCompleted, navigationHiddenByScroll]);

  useEffect(() => {
    let waitTimer;

    if (!signupNotificationSeen && !navigationHiddenByScroll) {
      waitTimer = setTimeout(() => {
        setHasWaited(true);
      }, 4500);
    }

    return () => {
      clearTimeout(waitTimer);
    };
  }, [navigationHiddenByScroll, signupNotificationSeen]);

  useEffect(() => {
    let shrinkTimer;
    let fadeTimer;
    let completeTimer;

    if (!signupNotificationSeen && !navigationHiddenByScroll) {
      setCookie({
        name: SIGNUP_NOTIFICATION_SEEN,
        value: true,
        expires: 15 * 60 * 1000,
      });

      if ((hasWaited && !isSignupBtnHovered && !panelHovered) || panelBlurred) {
        // allowing user to switch from panel to sign up button without fading text
        fadeTimer = setTimeout(() => {
          setFadeOutLinks(true);
        }, 300);
      }

      // once links fade, complete hiding nav
      if (fadeOutLinks) {
        // shrink panel
        shrinkTimer = setTimeout(() => {
          setShrink(true);
        }, 100);

        // send complete event to button
        completeTimer = setTimeout(() => {
          setVisibility(false);
          onComplete();
          setHasCompleted(true);
        }, 400);
      }
    } else {
      setVisibility(false);
    }

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(shrinkTimer);
      clearTimeout(completeTimer);
    };
  }, [
    onComplete,
    panelHovered,
    setCookie,
    signupNotificationSeen,
    fadeOutLinks,
    hasWaited,
    navigationHiddenByScroll,
    panelBlurred,
    isSignupBtnHovered,
  ]);

  const handlePanelBlurred = () => {
    setPanelBlurred(true);
  };

  if (signupNotificationSeen) return false;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className={[s.overlay, isVisible ? s.visible : ''].join(' ')} />
      <div
        className={[
          s.root,
          isVisible ? s.visible : '',
          shrink ? s.shrink : '',
          fadeOutLinks ? s.fadeOutLinks : '',
        ].join(' ')}
        data-testid="SignUpPanelRoot"
        onMouseEnter={() => setPanelHovered(true)}
        onMouseLeave={() => setPanelHovered(false)}
        ref={panelRef}
        onBlur={handlePanelBlurred}
        tabIndex="-1"
        style={{
          '--icon1': `url(${icon1})`,
          '--icon2': `url(${icon2})`,
          '--icon3': `url(${icon3})`,
          '--icon4': `url(${icon4})`,
        }}
      >
        <div className={s.inner}>
          <AnchorLink
            href={REGISTER}
            data-testid="register"
            onClick={() => {
              trackClickEvent({
                destination_url: REGISTER,
                label: 'Signup Popup',
                location: 'Nav Bar',
                vertical_index: 0,
                horizontal_index: 0,
              });
            }}
          >
            <div className={s.signUpTo}>Sign up to</div>
            <ul>
              <li>
                <div className={[s.icon, s.icon1].join(' ')} />
                <div className={s.tiny}>Find your Hogwarts</div>
                <div className={s.large}>House</div>
              </li>
              <li>
                <div className={[s.icon, s.icon2].join(' ')} />
                <div className={s.tiny}>Reveal your</div>
                <div className={s.large}>Patronus</div>
              </li>
              <li>
                <div className={[s.icon, s.icon3].join(' ')} />
                <div className={s.tiny}>Discover your</div>
                <div className={s.large}>Wand</div>
              </li>
              <li>
                <div className={[s.icon, s.icon4].join(' ')} />
                <div className={s.tiny}>Make your</div>
                <div className={s.large}>Portrait</div>
              </li>
            </ul>
          </AnchorLink>
        </div>
      </div>
    </>
  );
};

SignUpPanel.propTypes = propTypes;
export default SignUpPanel;
