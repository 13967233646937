/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash/snakeCase';
import { debounce } from 'lodash';
import {
  setBlur,
  useGlobalState as useNavigationGlobalState,
} from '@/src/globalStates/NavigationState';
import { fetchNavigation } from '@utils/contentfulHelpers';
import { useWindowWidth } from '@/src/hooks/windowHooks';
import { breakpoints } from '@/src/hooks/useBreakpoints';
import { trackClickEvent } from '@utils/analytics';
import { useUserContext } from '@/src/contexts/user-context';
import { SORTING_HAT } from '@/src/constants/routes';
import s from './Navigation.module.scss';
import { ReactComponent as ChevronNext } from '../../svgs/chevron_next.svg';
import Image from '../Image';
import Bumper from '../Bumper';
import SocialShareLinks from '../SocialShareLinks';
import navPromoImg from './img/nav-promo.png';
import AnchorLink from '../AnchorLink';

const ITEM_TYPE = 'navigationItem';
const GROUP_TYPE = 'navigationItemGroup';
const bumperedUrls = ['/store'];

const propTypes = {
  isSidebarOpen: PropTypes.bool,
  closeSidebar: PropTypes.func,
  navData: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileOnly: PropTypes.bool,
};

export const addActiveStateToItems = (items, currentPath) => {
  const { pathname } = window.location;
  const setPath = currentPath || pathname;
  const newItems = [...items];
  newItems.forEach((item) => {
    delete item.active;
    if (item.contentTypeId === ITEM_TYPE && item.url === setPath) {
      item.active = true; // eslint-disable-line no-param-reassign
    } else if (item.contentTypeId === GROUP_TYPE) {
      let hasActiveChild = false;
      item.items.forEach((childItem) => {
        childItem.active = false; // eslint-disable-line no-param-reassign
        if (childItem.url === setPath) {
          childItem.active = true; // eslint-disable-line no-param-reassign
          hasActiveChild = true;
        }
      });
      if (hasActiveChild) {
        item.active = true; // eslint-disable-line no-param-reassign
      }
    }
  });
  return newItems;
};

export const navRequiresBumper = (navItems) => {
  return !navItems.every((navItem) => {
    if (navItem.contentTypeId === GROUP_TYPE) {
      return !navRequiresBumper(navItem.items);
    }
    return bumperedUrls.every(
      (bumperedUrl) => !navItem.url || (navItem.url && !navItem.url.includes(bumperedUrl)),
    );
  });
};

const Navigation = ({
  isSidebarOpen = false,
  navData,
  closeSidebar = () => {},
  mobileOnly = false,
}) => {
  const { isLoggedIn } = useUserContext();
  const width = useWindowWidth();
  const [showingMobileMenu, setShowingMobileMenu] = useState(null);
  const [navigationHiddenByScroll] = useNavigationGlobalState('navigationHiddenByScroll');
  const [openRootText, setOpenRootText] = useState(null);
  const [navItems, setNavItems] = useState(navData.items);
  const [bumperProps, setBumperProps] = useState({
    link: '',
    onClose: /* istanbul ignore next */ () => {},
  });
  const [bumperState, setBumperState] = useState(false);
  const bumperRequired = navRequiresBumper(navItems);
  const [currentPath, setCurrentPath] = useState(null);

  useEffect(() => {
    setBlur(isSidebarOpen);
    if (isSidebarOpen) {
      document.body.classList.add('lock-scroll');
    } else {
      document.body.classList.remove('lock-scroll');
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    /* istanbul ignore next */
    if (openRootText && navigationHiddenByScroll) {
      setOpenRootText(null);
    }
  }, [navigationHiddenByScroll, openRootText]);

  useEffect(() => {
    const checkMobile = debounce(() => {
      setShowingMobileMenu(width <= breakpoints.lgg);
    }, 250);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, [showingMobileMenu, width]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchNavigation('site-navigation');
      // eslint-disable-next-line no-underscore-dangle
      if (data && Date.parse(data._updatedAt) > Date.parse(navData._updatedAt)) {
        setNavItems(data.items);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navData, setNavItems]);

  useEffect(() => {
    const { items } = navData;
    setNavItems(addActiveStateToItems(items, currentPath));
  }, [navData, currentPath]);

  const onBumper = (event, item) => {
    event.preventDefault();
    setBumperState(true);
    setBumperProps({
      link: item.url,
      eventName: 'CTA Clicked',
      onClose: () => {
        setBumperState(false);
        setCurrentPath(currentPath);
      },
    });
  };

  const handleHoverState = (mousedOver) => {
    if (!mousedOver && openRootText !== null) {
      setOpenRootText(null);
    }
  };

  const toggleRootText = (text, index) => {
    trackClickEvent({
      destination_url: window.location.pathname,
      label: text,
      location: 'Nav Bar',
      vertical_index: 0,
      horizontal_index: index + 1,
    });
    if (text === openRootText) {
      setOpenRootText(null);
    } else {
      setOpenRootText(text);
    }
  };

  const renderRootLinkItem = (item, index) => {
    const isOpen = openRootText === item.displayText;
    const navHasOpenState = openRootText !== null;
    return (
      <div
        id={snakeCase(item.displayText)}
        className={[
          s.navLink,
          isOpen ? s.openParent : '',
          isOpen ? 'openParent' : '',
          !isOpen && navHasOpenState ? s.closed : '',
          item.active ? s.activePage : '',
        ].join(' ')}
        role="button"
        tabIndex="0"
        onClick={() => {
          toggleRootText(item.displayText, index);
        }}
        onKeyUp={(e) => {
          // can't seem to get the else covered here
          /* istanbul ignore next */
          if (e.keyCode === 9) {
            setOpenRootText(item.displayText);
          }
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            toggleRootText(item.displayText, index);
          }
        }}
      >
        <div className={[s.linkTextContainer].join(' ')}>
          <div className={s.navigationLabel}>{item.displayText}</div>
          <ChevronNext className={[s.arrowIcon, isOpen ? s.arrowIconActive : ''].join(' ')} />
        </div>
      </div>
    );
  };

  const renderLinkItem = (item, secondaryLink, index, parentText = null) => {
    const isClosed = openRootText !== null;
    const itemImage = item?.image?.image?.file?.url;
    const shouldTriggerBumper = item.url && !bumperedUrls.every((url) => !item.url.includes(url));

    return (
      <AnchorLink
        prefetch={false}
        id={snakeCase(item.displayText)}
        className={[
          s.navLink,
          secondaryLink ? s.secondaryLink : s.nonSecondary,
          secondaryLink ? 'secondaryLink' : '',
          isClosed && !secondaryLink ? s.closed : '',
          item.active ? s.activePage : '',
        ].join(' ')}
        tabIndex={secondaryLink && (openRootText === null || parentText !== openRootText) ? -1 : 0}
        href={item.url}
        external={!item.isRelative}
        onClick={(e) => {
          trackClickEvent({
            destination_url: item.url,
            label: item.displayText,
            location: 'Nav Bar',
            vertical_index: 0,
            horizontal_index: index + 1,
          });
          if (shouldTriggerBumper) {
            onBumper(e, item);
          }
          setCurrentPath(item.url);
          setOpenRootText(null);
          closeSidebar(true);
        }}
      >
        <div className={[s.linkTextContainer].join(' ')}>
          {secondaryLink && (
            <div className={s.secondary}>
              <div className={s.steelMark} />
              <div className={s.imageWrapper}>
                <Image
                  imageSet={[
                    {
                      src: `${itemImage}?w=200&h=200&fit=fill&fm=webp 1x, ${itemImage}?w=400&h=400&fit=fill&fm=webp 2x, ${itemImage}?w=600&h=600&fit=fill&fm=webp 3x`,
                      type: 'image/webp',
                    },
                    {
                      src: `${itemImage}?w=200&h=200&fit=fill 1x, ${itemImage}?w=400&h=400&fit=fill 2x, ${itemImage}?w=600&h=600&fit=fill 3x`,
                    },
                    {
                      src: `${itemImage}?w=200&h=200&fit=fill`,
                    },
                  ]}
                  alt={item.displayText}
                  cover
                  lazy
                />
                <div className={s.textWrapper}>
                  <div className={s.displayText}>{item.displayText}</div>
                  <div className={s.dividerContainer}>
                    <div className={s.leftLine} />
                    <div className={s.unselectedDiamond}>
                      <div className={s.diamondInner} />
                    </div>
                    <div className={s.selectedDiamond} />
                    <div className={s.rightLine} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!secondaryLink && item.displayText}
        </div>
      </AnchorLink>
    );
  };

  const renderSecondaryNavigation = (links, parentText, parentIndex) => {
    const hasOpenParent = openRootText === parentText;

    return (
      <div className={[s.navSecondary, hasOpenParent ? s.hasOpenParent : ''].join(' ')}>
        <div
          className={s.secondaryBackground}
          style={{
            maxHeight:
              hasOpenParent && showingMobileMenu
                ? /* istanbul ignore next */ `${links.length * 35}px`
                : '',
          }}
        >
          <div className={s.secondaryInner}>
            <ul className={s.navSecondaryList}>
              {links.map((item, i) => {
                return (
                  <li key={item.contentfulId} id={`secondary-item-${i}`}>
                    {renderLinkItem(item, true, parentIndex, parentText)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderNavigation = () => (
    <>
      <ul className={s.navPrimary}>
        {navItems.map((item, i) => {
          if (item.contentTypeId === ITEM_TYPE) {
            return (
              <li key={item.contentfulId} id={`primary-item-${i}`}>
                {renderLinkItem(item, false, i)}
              </li>
            );
          }
          return (
            <li key={item.contentfulId} id={`primary-item-${i}`}>
              {renderRootLinkItem(item, i)}
              {renderSecondaryNavigation(item.items, item.displayText, i)}
            </li>
          );
        })}
        <li className={s.hideDesktop}>
          <div className={s.socialShareLinks}>
            <SocialShareLinks
              socialMediaItems={navData.socialMediaItems}
              mobile
              analyticsParams={{
                location: 'Nav Bar',
                vertical_index: 0,
              }}
            />
          </div>
        </li>
      </ul>
      {bumperRequired && <Bumper {...bumperProps} isOpen={bumperState} />}
    </>
  );

  return (
    <>
      <div
        data-testid="NavigationRoot"
        className={[
          s.darkPageBackground,
          openRootText !== null || isSidebarOpen ? s.show : '',
        ].join(' ')}
        onMouseEnter={() => setOpenRootText(null)}
        onMouseUp={() => {
          closeSidebar(true);
        }}
        role="button"
        tabIndex={-1}
      />
      <nav
        data-testid="navroot"
        className={[
          s.navRoot,
          isSidebarOpen ? s.showMobile : s.sidebarClosed,
          mobileOnly ? s.mobileOnly : '',
        ].join(' ')}
        onMouseEnter={() => handleHoverState(true)}
        onMouseLeave={() => handleHoverState(false)}
        style={{
          '--promo-bg': `url(${navPromoImg})`,
        }}
      >
        <div className={s.navScrollableContent}>
          {!isLoggedIn && (
            <AnchorLink
              prefetch={false}
              href={SORTING_HAT}
              className={s.navPromoContainer}
              data-testid="navPromoContainer"
              onClick={() => {
                trackClickEvent({
                  destination_url: SORTING_HAT,
                  label: 'Get Sorted',
                  location: 'Nav Bar',
                  vertical_index: 0,
                  horizontal_index: 0,
                  content_name: 'Discover Your Hogwarts House',
                });
                closeSidebar(true);
              }}
            >
              <div className={s.navPromo} />
            </AnchorLink>
          )}

          {renderNavigation()}
        </div>
        <div
          className={[s.secondaryBg, openRootText !== null ? s.showSecondaryBg : ''].join(' ')}
        />
      </nav>
    </>
  );
};

Navigation.propTypes = propTypes;
export default Navigation;
